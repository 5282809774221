import React, { useState, useMemo, useEffect } from "react";
import Table from "../Utilities/Table/Table";
import { getData, postData } from "../Services/AccessAPI";
import { toast } from "react-toastify";
import { Select, MenuItem, FormControl, InputLabel, CircularProgress, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { API_MODULES } from "../Services/Settings";

const Users = ({ affiliations }) => {
  const [selectedAffiliation, setSelectedAffiliation] = useState("");
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [features, setFeatures] = useState([]);
  // const [allFeatures, setAllFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isViewClicked, setIsViewClicked] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [groupFeatureMap, setGroupFeatureMap] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [checkedFeatures, setCheckedFeatures] = useState([]);
  const [subscriptionName, setSubscriptionName] = useState();
  const [subscriptionId, setSubscriptionId] = useState();

  const resetViewStates = () => {
    setIsViewClicked(false);
    setSelectedUserId(null);
    setSelectedGroup(null);
    setGroups([]);
    setFeatures([]);
    setCheckedGroups([]);
    setCheckedFeatures([]);
    setGroupFeatureMap({});
  };

  useEffect(() => {
    
    if (selectedAffiliation) {
      // Reset all states when affiliation changes
      resetViewStates();
      fetchUserData(selectedAffiliation);
    }
  }, [selectedAffiliation]);


  


  const fetchUserData = async (affiliationId) => {
    
    setIsLoading(true);
    try {
      // console.log("Fetching user data for affiliation ID:", affiliationId);
      let params = {affiliation_id: affiliationId};
      const [response, subscriptionResponse,activeOrgsResponse] = await Promise.all([
        getData(API_MODULES.ADMIN,"getUsersByAffiliation", params),
        getData(API_MODULES.ADMIN, 'getSubscriptionForAffiliation', params),
        getData(API_MODULES.ADMIN,'activeOrganisations')
      ]);

      const selectedSubscription = subscriptionResponse.data.subscriptionDetailList.find(sub => sub.is_selected);
      setSubscriptionName(selectedSubscription?.name);
      setSubscriptionId(selectedSubscription?.id);
      setUsers(response || []);
      setGroups(activeOrgsResponse);
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Error fetching users");
    } finally {
      setIsLoading(false);
    }
  };



const handleGroupCheckboxChange = (groupName) => {
  // Update the list of checked groups
  setCheckedGroups((prevCheckedGroups) => {
    const newCheckedGroups = prevCheckedGroups.includes(groupName)
      ? prevCheckedGroups.filter((group) => group !== groupName)
      : [...prevCheckedGroups, groupName]; 

    // Optionally update group-feature mapping
    setGroupFeatureMap((prevGroupFeatureMap) => {
      const newGroupFeatureMap = { ...prevGroupFeatureMap };

      if (newCheckedGroups.includes(groupName)) {
        // Ensure the group has an entry in the feature map
        newGroupFeatureMap[groupName] = newGroupFeatureMap[groupName] || [];
      } else {
        // Remove the group from the feature map if unchecked
        delete newGroupFeatureMap[groupName];
      }
      return newGroupFeatureMap;
    });
    return newCheckedGroups;
  });
};

const handleGroupViewButtonClick = async (groupName) => {
  // Toggle group selection if clicking the same group
  if (selectedGroup === groupName) {
    setSelectedGroup(null);
    setFeatures([]);
    setCheckedFeatures([]);
    return;
  }

  setSelectedGroup(groupName);
  
  try {
    // Make API call to get all features and also set the selected group features
    const featuresResponse = await getData(API_MODULES.ADMIN, "getAllFeatures");

    if (featuresResponse) {

      const allFeatures = featuresResponse || [];
      const groupFeatureNames = groupFeatureMap[groupName] || [];

      setFeatures(allFeatures);
      setCheckedFeatures(groupFeatureNames);
    }

  } catch (error) {
    console.error("Failed to fetch all features:", error);
    toast.error("Error fetching all features");
    setFeatures([]);
    setCheckedFeatures([]);
  }
};


const handleFeatureCheckboxChange = (featureName) => {
  
  if (selectedGroup) {
    setCheckedFeatures((prevCheckedFeatures) => {
      const newCheckedFeatures = prevCheckedFeatures.includes(featureName)
        ? prevCheckedFeatures.filter((name) => name !== featureName)
        : [...prevCheckedFeatures, featureName];

      setGroupFeatureMap((prevGroupFeatureMap) => {
        const newGroupFeatureMap = { ...prevGroupFeatureMap };
        if (newCheckedFeatures.includes(featureName)) {
          if (!newGroupFeatureMap[selectedGroup]) {
            newGroupFeatureMap[selectedGroup] = [];
          }
          newGroupFeatureMap[selectedGroup] = [
            ...new Set([...newGroupFeatureMap[selectedGroup], featureName]),
          ];
        } else {
          newGroupFeatureMap[selectedGroup] = (newGroupFeatureMap[selectedGroup] || []).filter(
            (name) => name !== featureName
          );
        }
        return newGroupFeatureMap;
      });
      return newCheckedFeatures;
    });
  } else {
    toast.warn("Please select a group first");
  }
};


  const handleViewButtonClick = async (user) => {

    const userId = user.userid

    
     // Toggle view if clicking the same user
    if (selectedUserId === userId) {
      setIsViewClicked(!isViewClicked);
      if (!isViewClicked) {
        // Reset group and feature related states when collapsing view
        setSelectedGroup(null);
        setFeatures([]);
        setCheckedFeatures([]);
      }
      return;
    }
    

    // Reset states when switching users
    setSelectedGroup(null);
    setFeatures([]);
    setCheckedFeatures([]);
    setSelectedUserId(userId);
    setIsViewClicked(true);


    try {
      // Fetch both user's group-feature data and active organizations simultaneously
      const [userGroupFeatureResponse, activeOrgsResponse] = await Promise.all([
        getData(API_MODULES.ADMIN, "getUsersGroupsFeatures", { user_id: userId }),
        // getData(API_MODULES.ADMIN, "activeOrganisations"),
      
      ]);

    
      if (userGroupFeatureResponse && userGroupFeatureResponse.data.length > 0){
        const groupFeatureData = JSON.parse(userGroupFeatureResponse.data[0].group_feature_data);
        // console.log("json parsed response: ", groupFeatureData)
  
        // Update state with fetched data
        
        setGroupFeatureMap(groupFeatureData);
        setCheckedGroups(Object.keys(groupFeatureData)); 
        // setGroups(activeOrgsResponse);
      } 
      else if (userGroupFeatureResponse.success && userGroupFeatureResponse.data.length === 0){
        toast.info("No groups or features found for this user");
      }
      else {
        resetGroupFeatureState();
      }
    } catch (error) {
      console.error("Error fetching group and feature data:", error);
      toast.error("Error fetching group and feature data");
      resetGroupFeatureState();
    }
    
  };
  
  const resetGroupFeatureState = () => {
    setCheckedGroups([]);
    setSelectedGroup(null);
    setCheckedFeatures([]);
    setGroupFeatureMap({})
  };

  
  const handleUpdateButtonClick = async () => {
    setIsUpdating(true);

    try {
    const updatedGroupFeatures = {};

    // First, preserve existing mappings for checked groups
    Object.entries(groupFeatureMap).forEach(([groupName, features]) => {
      if (checkedGroups.includes(groupName)) {
          updatedGroupFeatures[groupName] = [...features];
      }
    });

    // Update or add new groups with their features
    checkedGroups.forEach(groupName => {
      if (selectedGroup === groupName) {
          // For the currently selected group, use the checked features
          updatedGroupFeatures[groupName] = [...checkedFeatures];
      } else if (!updatedGroupFeatures[groupName]) {
          // For newly checked groups that weren't in the original mapping
          updatedGroupFeatures[groupName] = [...checkedFeatures];
      }
    });
    // console.log('Updated group features:', updatedGroupFeatures);
    const payload = {
      user_id: selectedUserId,
      affiliation_id: selectedAffiliation,
      groupFeatures: updatedGroupFeatures,
      subscription_name: subscriptionName,
    };

    const response = await postData(
      API_MODULES.ADMIN,
      "attachPoliciesToUser",
      {},
      payload
    );
    // console.log(response);
    if (response || response.success) {
      toast.success("Policies updated successfully");
      
      // Refresh data from the API
      try {
        
        // Get fresh data for the user
        const userGroupFeatureResponse = await getData(
          API_MODULES.ADMIN, 
          "getUsersGroupsFeatures", 
          { user_id: selectedUserId }
        );


        if (userGroupFeatureResponse?.data?.[0]?.group_feature_data) {
          const freshGroupFeatureData = JSON.parse(userGroupFeatureResponse.data[0].group_feature_data);
          setGroupFeatureMap(freshGroupFeatureData);
          setCheckedGroups(Object.keys(freshGroupFeatureData));
          if (selectedGroup && freshGroupFeatureData[selectedGroup]) {
            setCheckedFeatures(freshGroupFeatureData[selectedGroup]);
        }
      } else {
        resetGroupFeatureState();
      }
      // Optionally refresh groups list if needed
      const activeOrgsResponse = await getData(API_MODULES.ADMIN, "activeOrganisations");
      if (activeOrgsResponse) {
        setGroups(activeOrgsResponse);
      }
      } catch (refreshError) {
        console.error("Error refreshing data:", refreshError);
        toast.warning("Update successful but error refreshing display. Please refresh the page.");
      }
      } else {
        console.error("Update failed:", response);
        toast.error(response?.message || response?.error || "Failed to update policies");
      };
    } catch (error) {
      console.error("Error updating policies:", error);
      toast.error("Error updating policies");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleClearChanges = async () => {
    resetViewStates();
  };


  const userColumns = useMemo(
    () => [
      {
        Header: "User",
        //concat fullname
        accessor: (row) => {
          const fullName = `${row.firstname || ''} ${row.lastname || ''}`.trim();
          return fullName || 'N/A';
        },
        id: "user_name",
        sortType: "alphanumeric",
        Cell: ({ row }) => (
          <div
            
              className={`row-item ${row.original.userid === selectedUserId ? "selected-item" : ""}`}
              
              onClick={() => handleViewButtonClick(row.original)}
            >
              {row.original.firstname} {row.original.lastname || 'N/A'}
              {/* <FontAwesomeIcon
              icon={row.original.user_id === selectedUserId ? faAnglesRight : faAngleRight}
              className={`angle-icon ${row.original.user_id === selectedUserId ? 'selected' : ''}`}
              style={{
                color: row.original.user_id === selectedUserId ? "#333" : "#999",
              }}
            /> */}

            
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "alphanumeric",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row }) => (
          <div>
            <a
              className="view-button"
              tabIndex="0"
              onClick={() => handleViewButtonClick(row.original)}
            >
              View
            </a>
          </div>
        ),
      },
    ],
    [selectedUserId]
  );

  const groupColumns = useMemo(
    () => [
      {
        Header: () => (
          <div className="custom-header">
            <Tooltip title="Select All" placement="bottom-start">
              <input
                type="checkbox"
                checked={checkedGroups.length === groups.length}
                onChange={() => {
                  const allGroupNames = groups.map((group) => group.group_name);
                  const newCheckedGroups = checkedGroups.length === groups.length ? [] : allGroupNames;
  
                  setCheckedGroups(newCheckedGroups);
  
                  setGroupFeatureMap((prevGroupFeatureMap) => {
                    const newGroupFeatureMap = { ...prevGroupFeatureMap };
                    if (newCheckedGroups.length > 0) {
                      newCheckedGroups.forEach((groupName) => {
                        newGroupFeatureMap[groupName] = newGroupFeatureMap[groupName] || [];
                      });
                    } else {
                      allGroupNames.forEach((groupName) => {
                        delete newGroupFeatureMap[groupName];
                      });
                    }
                    return newGroupFeatureMap;
                  });
                }}
              />
            </Tooltip>
          </div>
        ),
        accessor: "checkbox",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={checkedGroups.includes(row.original.group_name)}
            onChange={() => handleGroupCheckboxChange(row.original.group_name)}
          />
        ),
      },
      {
        Header: "Group",
        accessor: "group_name",
        sortType: "alphanumeric",
        Cell: ({ row }) => (
          <div key={setSelectedGroup}
            className={`row-item ${
              row.original.group_name === selectedGroup ? "selected-item" : ""
            }`}
            onClick={() => handleGroupViewButtonClick(row.original.group_name)}
          >
            {row.original.group_name}
            <FontAwesomeIcon
              icon={row.original.group_name === selectedGroup ? faAnglesRight : faAngleRight}
              className={`angle-icon ${row.original.group_name === selectedGroup ? 'selected' : ''}`}
              style={{
                color: row.original.group_name === selectedGroup ? "#333" : "#999",
              }}
            />
          </div>
        ),
      },
    ],
    [selectedGroup, groups, checkedGroups]
  );

  const featureColumns = useMemo(
    () => [
      {
        Header: () => (
          <div className="custom-header">
            <Tooltip title="Select All" placement="bottom-start">
              <input
                type="checkbox"
                checked={ checkedFeatures.length === features.length}
                onChange={() => {
                  const allFeatureNames = features.map((feature) => feature.feature_name);
                  const newCheckedFeatures = checkedFeatures.length === features.length ? [] : allFeatureNames;
                  setCheckedFeatures(newCheckedFeatures);
  
                  setGroupFeatureMap((prevGroupFeatureMap) => {
                    const newGroupFeatureMap = { ...prevGroupFeatureMap };
                    if (selectedGroup) {
                      if (newCheckedFeatures.length > 0) {
                        newGroupFeatureMap[selectedGroup] = newCheckedFeatures;
                      } else {
                        newGroupFeatureMap[selectedGroup] = [];
                      }
                    }
                    return newGroupFeatureMap;
                  });
                }}
              />
            </Tooltip>
          </div>
        ),
        accessor: "checkbox",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={checkedFeatures.includes(row.original.feature_name)}
            onChange={() =>
              handleFeatureCheckboxChange(row.original.feature_name)
            }
          />
        ),
      },
      {
        Header: "Feature",
        accessor: "feature_name",
        sortType: "alphanumeric",
      },
    ],
    [checkedFeatures, features]
  );

  return (
    <>
      <FormControl variant="outlined" className="affiliation-select" sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel>Affiliation</InputLabel>
        <Select
          value={selectedAffiliation}
          onChange={(e) => setSelectedAffiliation(e.target.value)}
          label="Affiliation"
        >
          {affiliations.map((affiliation) => (
          
            <MenuItem
              key={affiliation.affiliate_id}
              value={affiliation.affiliate_id}
            >
              {affiliation.affiliate_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className="table-container">
        {isLoading && <LoadingSpinner />}
        <div className={`entity-data ${
                users.data && users.data.length > 0 ? "" : "full-width"
              }`}
        >
          {users.data && users.data.length ? (
            <Table
              columns={userColumns}
              data={users.data}
              disableFilterOptionsForIds={["action"]}
            />
          ) : (
            !isLoading && <p className="no-data-text">Select an Affiliation from the dropdown menu</p>
          )}
        </div>
        {isViewClicked && (
          <div className="entity-view-data">
            <div className="entity-side-panel">
              <div className="groups-data">
                {groups.length > 0 ? (
                  <Table
                    columns={groupColumns}
                    data={groups}
                    disableFilterOptionsForIds={["checkbox"]}
                    tableDataHeight={"400px"}
                    showPagination={false}
                  />
                ) : (
                  <p className="no-data-text">Select a User</p>
                )}
              </div>
              {selectedGroup && (
              <div className="features-data">
                {features.length > 0 ? (
                  <Table
                    columns={featureColumns}
                    data={features}
                    disableFilterOptionsForIds={["checkbox"]}
                    tableDataHeight={"400px"}
                    showPagination={false}
                  />
                ) : (
                  <p className="no-data-text">Select a Group</p>
                )}
              </div>
              )}
            </div>
            <div className="options-container">
              <button
                type= "button"
                className="option-button clear"
                onClick={handleClearChanges}
              >
                Clear Changes
              </button>
              <button
                type= "button"
                className="option-button update"
                onClick={handleUpdateButtonClick}
                disabled={isUpdating}
              >
                {isUpdating && (
                  <CircularProgress
                    size={20}
                    style={{ marginRight: "8px", color: "white" }}
                  />
                )}
                Update
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Users;