// import React, { useState } from "react";
// import { useTable, usePagination, useSortBy, useFilters } from "react-table";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faSort,
//   faSortUp,
//   faSortDown,
//   faFilter,
//   faChevronUp,
//   faChevronDown,
// } from "@fortawesome/free-solid-svg-icons";
// import "./TableWithAccordian.css";
// import { ColumnFilter } from "../Utilities/Table/ColumnFilter";
// import { Tooltip } from "@mui/material";

// const TableWithAccordian_New = ({
//   columns = [],
//   data = [],
//   pageSizeOptions = [10, 25, 50],
//   showPagination = true,
//   showFilterOptions = true,
//   disableFilterOptionsForIds = [],
//   defaultShowFilterForIds = [],
//   tableDataHeight,
// }) => {
//   const [selectedRow, setSelectedRow] = useState(null);
//   const [activeFilterColumns, setActiveFilterColumns] = useState(
//     defaultShowFilterForIds
//   );
//   const [expandedRows, setExpandedRows] = useState({});

//   const defaultColumn = React.useMemo(
//     () => ({
//       Filter: ColumnFilter,
//     }),
//     []
//   );
//   // Table rendering logic goes here
//   // Create a table instance with pagination and sorting features
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     page,
//     prepareRow,
//     state: { pageIndex, pageSize },
//     gotoPage,
//     previousPage,
//     nextPage,
//     canPreviousPage,
//     canNextPage,
//     pageCount,
//     setPageSize,
//   } = useTable(
//     {
//       columns,
//       data,
//       initialState: { pageIndex: 0 },
//       style: {
//         height: "400px", // Set the height of the table body
//       },
//       defaultColumn,
//     },
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const handleToggleSelectedRow = (rowId) => {
//     setSelectedRow((prevSelectedRow) =>
//       prevSelectedRow === rowId ? null : rowId
//     );
//     setExpandedRows((prevExpandedRows) => ({
//       ...prevExpandedRows,
//       [rowId]: !prevExpandedRows[rowId],
//     }));
//   };

//   // const handleToggleRow = (rowId) => {
//   //   setExpandedRows((prevExpandedRows) => ({
//   //     ...prevExpandedRows,
//   //     [rowId]: !prevExpandedRows[rowId],
//   //   }));
//   // };

//   return (
//     <div className="react-table-container">
//       <div
//         className="table-scroll-container"
//         style={tableDataHeight ? { height: tableDataHeight } : null}
//       >
//         <table {...getTableProps()} className="react-table">
//           <thead>
//             {headerGroups.map((headerGroup) => (
//               <tr {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map((column) =>
//                   showFilterOptions &&
//                   !disableFilterOptionsForIds.includes(column.id) ? (
//                     <th
//                       {...column.getHeaderProps(column.getSortByToggleProps())}
//                       className={
//                         column.isSorted
//                           ? column.isSortedDesc
//                             ? "desc"
//                             : "asc"
//                           : ""
//                       }
//                     >
//                       <div className="column-header" title="">
//                         <div className="column-header-title">
//                           <Tooltip title="Toggle Sort" placement="bottom-end">
//                             <span className="header-sort">
//                               <span>{column.render("Header")}</span>
//                               <span className="sort-icon">
//                                 {column.isSorted ? (
//                                   column.isSortedDesc ? (
//                                     <FontAwesomeIcon icon={faSortDown} />
//                                   ) : (
//                                     <FontAwesomeIcon icon={faSortUp} />
//                                   )
//                                 ) : (
//                                   <FontAwesomeIcon icon={faSort} />
//                                 )}
//                               </span>
//                             </span>
//                           </Tooltip>
//                           <Tooltip
//                             title="Toggle Filter"
//                             placement="bottom-start"
//                           >
//                             <span
//                               className={`filter-icon ${
//                                 activeFilterColumns.includes(column.id)
//                                   ? "active"
//                                   : ""
//                               }`}
//                               onClick={(e) => {
//                                 e.stopPropagation();
//                                 setActiveFilterColumns((prevColumns) => {
//                                   if (prevColumns.includes(column.id)) {
//                                     return prevColumns.filter(
//                                       (id) => id !== column.id
//                                     );
//                                   } else {
//                                     return [...prevColumns, column.id];
//                                   }
//                                 });
//                               }}
//                             >
//                               <FontAwesomeIcon icon={faFilter} />
//                             </span>
//                           </Tooltip>
//                         </div>
//                         {activeFilterColumns.includes(column.id) && (
//                           <div className="column-header-filter">
//                             {column.canFilter ? (
//                               <div onClick={(e) => e.stopPropagation()}>
//                                 {column.render("Filter")}
//                               </div>
//                             ) : null}
//                           </div>
//                         )}
//                       </div>
//                     </th>
//                   ) : (
//                     <th {...column.getHeaderProps()}>
//                       {column.render("Header")}
//                     </th>
//                   )
//                 )}
//               </tr>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()} className="table-body-container">
//             {showPagination
//               ? page.map((row) => {
//                   prepareRow(row);
//                   const isSelected = row.id === selectedRow;

//                   return (
//                     <React.Fragment key={row.id}>
//                       <tr
//                         {...row.getRowProps()}
//                         className={isSelected ? "selected-row" : ""}
//                       >
//                         {row.cells.map((cell, index) => (
//                           <td
//                             {...cell.getCellProps()}
//                             onClick={() => handleToggleSelectedRow(row.id)}
//                             style={{
//                               cursor: "pointer",
//                               display: "flex",
//                               paddingRight: "16px",
//                               justifyContent: "space-between",
//                             }}
//                           >
//                             {cell.render("Cell")}
//                             {index === row.cells.length - 1 && (
//                               <span style={{ marginLeft: "0.5rem" }}>
//                                 <FontAwesomeIcon
//                                   icon={
//                                     expandedRows[row.id]
//                                       ? faChevronUp
//                                       : faChevronDown
//                                   }
//                                   className="accordion-icon"
//                                 />
//                               </span>
//                             )}
//                           </td>
//                         ))}
//                       </tr>
//                       {expandedRows[row.id] && (
//                         <tr>
//                           <td className="accordian-text">
//                             <span className="accordian-text header">
//                               <span className="text-row">
//                                 <span className="text-title">Email: </span>
//                                 <span className="text-data">
//                                   {row.original.Email}
//                                 </span>
//                               </span>
//                               <span className="text-row">
//                                 <span className="text-title">Department: </span>
//                                 <span className="text-data">
//                                   {row.original.Department}
//                                 </span>
//                               </span>
//                               <span className="text-row">
//                                 <span className="text-title">
//                                   Designation:{" "}
//                                 </span>
//                                 <span className="text-data">
//                                   {row.original.Designation.endsWith(",")
//                                     ? row.original.Designation.slice(0, -1)
//                                     : row.original.Designation}
//                                 </span>
//                               </span>
//                               <span className="text-row">
//                                 <span className="text-title">
//                                   Aggregated Similarity Score:{" "}
//                                 </span>
//                                 <span className="text-data">
//                                   {(
//                                     row.original.AggregatedSimilarityScore * 100
//                                   ).toFixed(2) + "%"}
//                                 </span>
//                               </span>
//                             </span>
//                             <span className="accordian-text body">
//                               <span className="text-title">
//                                 Matching text:{" "}
//                               </span>
//                               <div className="text-data">
//                                 <ul>
//                                   {row.original.MostSimilarChunks.map(
//                                     (chunk, index) => (
//                                       <li key={index}>{chunk}</li>
//                                     )
//                                   )}
//                                 </ul>
//                               </div>
//                             </span>
//                           </td>
//                         </tr>
//                       )}
//                     </React.Fragment>
//                   );
//                 })
//               : rows.map((row) => {
//                   prepareRow(row);
//                   const isSelected = row.id === selectedRow;

//                   return (
//                     <tr
//                       {...row.getRowProps()}
//                       className={isSelected ? "selected-row" : ""}
//                     >
//                       {row.cells.map((cell) => {
//                         return (
//                           <td
//                             {...cell.getCellProps()}
//                             onClick={() => handleToggleSelectedRow(row.id)}
//                           >
//                             {cell.render("Cell")}
//                           </td>
//                         );
//                       })}
//                     </tr>
//                   );
//                 })}
//           </tbody>
//         </table>
//       </div>

//       {showPagination && (
//         <div className="pagination">
//           <span className="page-details">
//             Page{" "}
//             <strong>
//               {pageIndex + 1} of {pageCount}
//             </strong>{" "}
//           </span>
//           <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
//             {"<<"}
//           </button>
//           <button onClick={() => previousPage()} disabled={!canPreviousPage}>
//             {"<"}
//           </button>
//           <button onClick={() => nextPage()} disabled={!canNextPage}>
//             {">"}
//           </button>
//           <button
//             onClick={() => gotoPage(pageCount - 1)}
//             disabled={!canNextPage}
//           >
//             {">>"}
//           </button>
//           <div className="pagination-options">
//             <select
//               value={pageSize}
//               onChange={(e) => {
//                 setPageSize(Number(e.target.value));
//               }}
//             >
//               {pageSizeOptions.map((pageSize) => (
//                 <option key={pageSize} value={pageSize}>
//                   {"Show "}
//                   {pageSize}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TableWithAccordian_New;

import React, { useState } from "react";
import { useTable, usePagination, useSortBy, useFilters } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import "./TableWithAccordian.css";
import { ColumnFilter } from "../Utilities/Table/ColumnFilter";
import { Tooltip } from "@mui/material";

const TableWithAccordian_New = ({
  columns = [],
  data = [],
  pageSizeOptions = [10, 25, 50],
  showPagination = true,
  showFilterOptions = true,
  disableFilterOptionsForIds = [],
  defaultShowFilterForIds = [],
  tableDataHeight,
  onViewClick,
  selectedFacultyId
}) => {
  // State for managing expanded rows and active filters
  const [activeFilterColumns, setActiveFilterColumns] = useState([]);

  // Enhance columns to include View action if not present
  const enhancedColumns = React.useMemo(() => {
    const hasActionColumn = columns.some(col => col.Header === 'Action');
    
    if (!hasActionColumn) {
      return [...columns, {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <a 
            className="view-link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onViewClick(row.original);
            }}
            href="#"
            tabIndex="0"
          >
            View
          </a>
        ),
        disableFilters: true,
        disableSortBy: true
      }];
    }
    return columns;
  }, [columns, onViewClick]);

  // Default column configuration for filters
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  // Initialize table instance with all necessary features
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns: enhancedColumns,
      data,
      defaultColumn: {Filter: ColumnFilter },
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // Function to render the expanded content for a row
  // const renderExpandedContent = (rowData) => (
  //   <div className="expanded-content">
  //     <div className="expanded-section">
  //       {/* Header section with name and designation */}
  //       <div className="expanded-header">
  //         <div className="faculty-info">
  //           <h3 className="faculty-name">{rowData.Name}</h3>
  //           <p className="faculty-designation">{rowData.Designation}</p>
  //         </div>
  //       </div>
        
  //       {/* Details section with email, department, and score */}
  //       <div className="faculty-details">
  //         <div className="detail-row">
  //           <span className="detail-label">Email:</span>
  //           <span className="detail-value">{rowData.Email}</span>
  //         </div>
  //         <div className="detail-row">
  //           <span className="detail-label">Department:</span>
  //           <span className="detail-value">{rowData.Department}</span>
  //         </div>
  //         <div className="detail-row">
  //           <span className="detail-label">Similarity Score:</span>
  //           <span className="detail-value">
  //             {(rowData.AggregatedSimilarityScore * 100).toFixed(2)}%
  //           </span>
  //         </div>
  //       </div>

  //       {/* Matching content section */}
  //       <div className="matching-content">
  //         <h4>Matching Content:</h4>
  //         <div className="matching-chunks">
  //           {rowData.MostSimilarChunks.map((chunk, index) => (
  //             <p key={index} className="chunk-text">{chunk}</p>
  //           ))}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="react-table-container">
      <div
        className="table-scroll-container"
        // style={tableDataHeight ? { height: tableDataHeight } : null}
      >
        <table {...getTableProps()} className="react-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(
                      // Only apply sort props if it's not the Action column
                      column.Header !== 'Action' ? column.getSortByToggleProps() : {}
                    )}
                    className={column.isSorted ? (column.isSortedDesc ? "desc" : "asc") : ""}
                  >
                    <div className="column-header">
                      <div className="column-header-title">
                        {/* Sort functionality */}
                        {/* For non-Action columns, show sort and filter options */}
                        {column.Header !== 'Action' ? (
                          <>
                        <Tooltip title="Toggle Sort" placement="bottom-end">
                          <span className="header-sort">
                            {column.render("Header")}
                            {column.canSort && (
                              <span className="sort-icon">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <FontAwesomeIcon icon={faSortDown} />
                                  ) : (
                                    <FontAwesomeIcon icon={faSortUp} />
                                  )
                                ) : (
                                  <FontAwesomeIcon icon={faSort} />
                                )}
                              </span>
                            )}
                          </span>
                        </Tooltip>
                        
                        {/* Filter toggle button */}
                        {showFilterOptions && !disableFilterOptionsForIds.includes(column.id) && (
                          <Tooltip title="Toggle Filter" placement="bottom-start">
                            <span
                              className={`filter-icon ${
                                activeFilterColumns.includes(column.id) ? "active" : ""
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveFilterColumns((prev) =>
                                  prev.includes(column.id)
                                    ? prev.filter((id) => id !== column.id)
                                    : [...prev, column.id]
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faFilter} />
                            </span>
                          </Tooltip>
                        )}
                        </>
                      ) : (
                        // For Action column, just show the header text without any icons
                        <span>{column.render("Header")}</span>
                      )}
                    </div>
                      
                      {/* Filter input field */}
                      {/* Only show filter input for non-Action columns */}
                      {column.Header !== 'Action' && activeFilterColumns.includes(column.id) && column.canFilter && (
                        <div className="column-header-filter" onClick={(e) => e.stopPropagation()}>
                          {column.render("Filter")}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const isSelected = row.original.staffId === selectedFacultyId;
              // return (
              //   <React.Fragment key={row.id}>
              //     {/* Regular row content */}
              //     <tr {...row.getRowProps()}>
              //       {row.cells.map((cell) => (
              //         <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              //       ))}
              //     </tr>
              //   </React.Fragment>
              // );
              return (
                <tr
                  {...row.getRowProps()}
                  className={isSelected ? 'selected-row' : ''}
                >
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Pagination controls */}
      {showPagination && (
        <div className="pagination">
          <span className="page-details">
            Page <strong>{pageIndex + 1}</strong> of <strong>{pageCount}</strong>
          </span>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </button>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {pageSizeOptions.map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default TableWithAccordian_New;