import React, { useState } from "react";
import "./Login.css";
import asu_ker_logo from "../../images/asu_ker_logo.png";
import { toast } from "react-toastify";
import { postData, postDataForLoginAndRegister } from "../Services/AccessAPI";
import SessionManager from "./SessionManager";
import { Link } from "react-router-dom";
import { API_MODULES } from "../Services/Settings";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleInputFocus = (e) => {
    const inputContainer = e.target.parentNode;
    inputContainer.classList.add("focused");
  };

  const handleInputBlur = (e) => {
    const inputContainer = e.target.parentNode;
    if (e.target.value === "") {
      inputContainer.classList.remove("focused");
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loadId = toast.loading("Validating Credentials");
  
    const userInfo = {
      email: username,
      password: password,
      rememberMe: true,
    };
  
    try {
      const response = await postData(API_MODULES.LOGIN, "login", userInfo);
      const result = await response.json();
      
      if (result?.data?.access_token) {
        const { access_token, refresh_token, firstName, lastName } = result.data;
        const fullName = `${firstName} ${lastName}`;

        SessionManager.setUserSession(access_token, fullName, refresh_token);
        SessionManager.setSelectedGroup();
  
        if (SessionManager.getToken()) {
          toast.update(loadId, {
            render: "Login successful!",
            type: "success",
            isLoading: false,
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          // Redirect to dashboard
          window.location.href = "/dashboard";
        }
      } else {
        toast.update(loadId, {
          render: "Incorrect Login Credentials!",
          type: "error",
          isLoading: false,
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.error("Error Logging in: ", error);
      toast.update(loadId, {
        // render: "An error occurred during login. Please try again.",
        render: "Incorrect Login Credentials!",
        type: "error",
        isLoading: false,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };
  

  return (
    <div>
      {/* <App /> */}
      <div className="login-container">
        <div className="login-box">
          <img src={asu_ker_logo} alt="Login" className="login-image" />
          <h2>Knowledge Alliance Tool</h2>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                type="email"
                name="username"
                value={username}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={onKeyDown}
                required
              />
              <label>Username or Email</label>
            </div>
            <div className="input-container">
              <input
                type="password"
                name="password"
                value={password}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={onKeyDown}
                required
              />
              <label>Password</label>
            </div>
            <button type="submit">Log in</button>
          </form>
          <Link to="/forgotpassword">
            <span className="forgot-password-btn">Forgot Password</span>
          </Link>
          <Link to="/register">
            <span className="register-btn">New user? Register</span>
          </Link>
        </div>
        <div className="login-footer-info">
          <h1>Knowledge Exchange For Resilience</h1>
          <p>©2018 All Rights Reserved. Knowledge Exchange For Resilience!</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
