import API_ENDPOINTS from '../../config/apiEndpoints';
import { Modules } from '../../config/constants';

export const BASE_URL = window.baseURL || "http://localhost:5006/";
// export const BASE_URL = window.baseURL || "https://localhost:7235/";
export const FAST_API_BASE_URL = window.fastApiBaseURL || "http://localhost:8001/";
export const AIR_API_BASE_URL = window.aiirApiBaseURL || "http://localhost:6568/";
export const NEW_AIIR_API_BASE_URL = window.aiirApiBaseURL || "http://localhost:6568/";
export const SEMANTIC_SEARCH_API_URL = window.semanticSearchApiURL || "http://localhost:1230/";
export const UPLOADS_BASE_URL = window.uploadsApiBaseURL;
export const API_MODULES = Modules;
export { API_ENDPOINTS };