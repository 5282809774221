import React, { useState, useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { WithContext as ReactTags } from "react-tag-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faShareFromSquare, faTimes, faFileExport } from "@fortawesome/free-solid-svg-icons";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import "./AIR.css";
import { Tooltip } from "@mui/material";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { toast } from "react-toastify";
import TableWithAccordian_New from "./TableWithAccordian_New";
import { getDataFromAiirAPI, postData, getData } from "../Services/AccessAPI";
import { capitalizeFirstLetter } from "../Utilities/utils";
import { API_MODULES } from "../Services/Settings";
import Avatar from '../../images/Team/Avatar.png';

const tabFeatureMapping = {
  aiir_organization: "aiir_organization",
  aiir_faculty: "aiir_faculty",
};

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const AIIR_New = ({ user, selectedGroup }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("aiir_faculty");
  const [isLoading, setIsLoading] = useState(false);
  const [emailToStaffId, setEmailToStaffId] = useState({});
  const [facultyData, setFacultyData] = useState([]);
  const [facultyDetailData, setFacultyDetailData] = useState(null);
  const [orgData, setOrgData] = useState([]);
  const [cache, setCache] = useState({});
  const [showData, setShowData] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [tags, setTags] = useState({});
  const [staffId, setStaffId] = useState(null);
  const [accordionState, setAccordionState] = useState({
    biography: false,
    research: false,
    publicWork: false,
    matchingContent: false
  });
  const [interestTopics, setInterestTopics] = useState([]);

  const facultyInfoRef = useRef();
  const facultyCitationRef = useRef();

  const handleImageError = () => {
    setImageError(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    // Clear search-related states
    setSearchTerm("");
    setFacultyData([]);
    setOrgData([]);
    setIsApiCalled(false);

    // Clear faculty detail view states
    setSelectedFaculty(null);
    setFacultyDetailData(null);
    setShowData(false);


  };

  


  const getAvailableTabs = () => {
    if (!user || !selectedGroup) return [];

    const group = user?.attached_policies?.find(policy => policy.group_name === selectedGroup);
    if (!group) return [];

    return group?.features?.map(f => f[1]);
  };

  const availableTabs = getAvailableTabs();

  const checkIfTagExists = (tags, tagLabel) => {
    if (!Array.isArray(tags)) return false;
    return tags.some((tag) => tag.tagLabel.toLowerCase() === tagLabel.toLowerCase());
  }

  const handleInsertTag = async (tagLabel, facultyEmail) => {

    // Get staffId for the email
    const facultyId = await getStaffIdForEmail(facultyEmail);
    if (!facultyId) {
      console.error("Could not retrieve staffId");
    }
    // facultyId = staffId;
    const existingTags = tags[facultyEmail] || [];
    // const tagAlreadyExists = existingTags.some((tag) => tag.tagLabel === tagLabel);
    const tagAlreadyExists = checkIfTagExists(existingTags, tagLabel);

    if (tagAlreadyExists) {
      toast.warn("Tag already exists!");
      return;
    }

    if (existingTags.length >= 3) {
      toast.warn(
        <div>
          Tag limit exceeded!
          <br /> Cannot add more than 3 tags
        </div>
      );
      return;
    }

    try {
      
      const params = { tag_label: tagLabel, faculty_id: facultyId };
      const insertTagForFacultyResponse = await postData(API_MODULES.TAGS, 'insertTagForFaculty', params);
      const tagData = await insertTagForFacultyResponse.json();
      
      if (!insertTagForFacultyResponse.ok) {
        throw new Error("Failed to insert tag.");
      }

      setTags((prevTags) => {
        const updatedTags = { ...prevTags };
        // console.log("Before update:", updatedTags[facultyEmail]);
        if (updatedTags.hasOwnProperty(facultyEmail)) {
          const existingTags = updatedTags[facultyEmail];
          // const tagAlreadyExists = existingTags.some((tag) => tag.tagLabel === tagLabel);
          const tagAlreadyExists = checkIfTagExists(existingTags, tagLabel);
          if (!tagAlreadyExists) {
            const updatedFacultyTags = [
              ...existingTags,
              {
                id: tagData.data.toString(),
                tagLabel: tagLabel,
                tagId: String(tagData.data),
              },
            ];
            updatedTags[facultyEmail] = updatedFacultyTags;
          }
        } else {
          updatedTags[facultyEmail] = [{ id: "1", tagLabel: tagLabel, tagId: tagData.data, }];
        }
        // console.log("After update:", updatedTags[facultyEmail]);
        return updatedTags;
      });

      setSuggestedTags((prevTags) => {
        // const tagAlreadyExists = prevTags.some((tag) => tag.tagLabel === tagLabel);
        const currentTags = Array.isArray(prevTags) ? prevTags : [];
        const tagAlreadyExists = checkIfTagExists(currentTags, tagLabel);
        if (tagAlreadyExists) {
          return currentTags;
        } else {
          return [
            ...currentTags,
            { id: (currentTags.length + 1).toString(), tagLabel: tagLabel },
          ];
        }
      });

      toast.success("Tag added successfully!");
    } catch (error) {
      toast.error("Failed to add tag!");
      console.error("Error inserting tag:", error);
    }
  };

  const handleDownloadPdf = async () => {
    // Ensure both refs are present
    if (!facultyInfoRef.current || !facultyCitationRef.current) {
      console.error("Missing references.");
      return;
    }

    // Capture both sections as canvas images
    const canvasInfo = await html2canvas(facultyInfoRef.current, {
      useCors: true,
      scale: 1,
    }); // Adjust scale as needed
    const canvasCitation = await html2canvas(facultyCitationRef.current, {
      useCors: true,
      scale: 1,
    }); // Adjust scale as needed

    // Convert canvases to data URLs
    const dataInfo = canvasInfo.toDataURL("image/png");
    const dataCitation = canvasCitation.toDataURL("image/png");

    // Initialize jsPDF
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const padding = 10; // Define padding around images

    // Define a generic function to add images to PDF with padding
    const addImageToPdf = (dataUrl, xPos, yPos) => {
      const imgProps = pdf.getImageProperties(dataUrl);
      const availableWidth = pdfWidth / 2 - 2 * padding; // Half width minus padding for each side
      const scaledHeight = (imgProps.height * availableWidth) / imgProps.width; // Scale height to maintain aspect ratio

      pdf.addImage(
        dataUrl,
        "PNG",
        xPos + padding,
        yPos + padding,
        availableWidth,
        scaledHeight
      );
      return scaledHeight + 2 * padding; // Height of the image including padding
    };

    // Add both images to the PDF, side by side, with padding
    const infoImageHeight = addImageToPdf(dataInfo, 0, 0); // Left half, starting yPos = 10
    const citationImageHeight = addImageToPdf(dataCitation, pdfWidth / 2, 0); // Right half, starting yPos = 10

    // Determine the starting position for the next section of the PDF content
    let yOffset = Math.max(infoImageHeight, citationImageHeight) - 10; // '-10' to reduce the gap btwn images and next heading

    const textData = [
      { heading: "Interest Topics", content: facultyData?.interestTopics },
      { heading: "Biography", content: facultyData?.bio },
      { heading: "Research", content: facultyData?.research },
      { heading: "Public Work", content: facultyData?.publicWork },
    ];

    // let yOffset = pdfHeight + 10; // Start Y position for text

    textData.forEach(({ heading, content }) => {
      if (content) {
        const paragraphs =
          heading === "Interest Topics"
            ? [content]
            : content.split("\n").join("\n\n").split("\n");
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text(10, yOffset, heading);

        yOffset += 8; // Add some padding below the heading

        paragraphs.forEach((paragraph) => {
          const lines = pdf.splitTextToSize(paragraph, pdfWidth - 20); // Adjust for margins
          const lineHeight = pdf.getLineHeight() / pdf.internal.scaleFactor;
          if (yOffset + lineHeight > pdf.internal.pageSize.getHeight() - 20) {
            pdf.addPage(); // Add a new page if the content exceeds the current page
            yOffset = 10; // Reset Y offset for the new page
          }
          lines.forEach((line) => {
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(10);
            pdf.text(10, yOffset, line);
            yOffset += lineHeight;
          });
        });

        yOffset += 6; // Add some padding between sections
      }
    });

    pdf.save(`Faculty Profile - ${facultyData?.name}.pdf`);
  };

  // New handlers for enhanced functionality
  const handleViewFaculty = async (faculty) => {
    setIsLoading(true);
    try {
      
      setSelectedFaculty(faculty);
      const staffId = await getStaffIdForEmail(faculty.Email);
      if (!staffId) {
        console.error("Could not retrieve staffId");
      }
      const params = {
            staffid: staffId,
            // staffid: "ASU-006756",
            group_name: localStorage.getItem("selectedGroup")
          };
      
      const response = await getData(API_MODULES.SEARCH, 'getFacultyDetailedProfile', params); 
      // console.log(response)

      if (response?.success && response.data && response.data.length > 0) {
        

        const detailedProfile = response.data[0];
        // console.log("Detailed Profile:", detailedProfile);

        // setFacultyDetailData(detailedProfile);
        setFacultyDetailData({
          ...detailedProfile,
          MostSimilarChunks: faculty.MostSimilarChunks || []
        });
        setShowData(true);

        if (detailedProfile.interestTopics) {
          const topics = detailedProfile.interestTopics.split(",").map(topic => topic.trim());
          setInterestTopics(topics);
        } else {
            setInterestTopics([]);
        }

        setImageError(false);
      } 
    } catch (error) {
      console.error("Error details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleAccordion = (accordionName) => {
    setAccordionState(prev => ({
      ...prev,
      [accordionName]: !prev[accordionName]
    }));
  };

  const handleAddTag = (facultyId, tagText) => {
    if (!tagText.trim() || (tags[facultyId]?.length || 0) >= 3) return;
    
    setTags(prev => ({
      ...prev,
      [facultyId]: [...(prev[facultyId] || []), {
        id: Date.now().toString(),
        text: tagText.trim()
      }]
    }));
  };

  const handleRemoveTag = async (i, facultyEmail) => {
    console.log("in handleRemoveTag: ", facultyEmail, i);
    // Get staffId for the email
    const facultyId = await getStaffIdForEmail(facultyEmail);
    if (!facultyId) {
      console.error("Could not retrieve staffId");
    }
    // facultyId = staffId;
    // console.log("in handleRemoveTag: ", tags[facultyId], i);
    // let tag = tags[facultyEmail][i];
    let tag = tags[facultyEmail]?.[i];
    if (!tag) {
        toast.error("Tag not found!");
        return;
    }

    console.log(tag);
    try {
      // Update our API parameters to use email instead of faculty_id
      // This change reflects our new identification method
      const updateTagParams = {
          faculty_id: facultyId, 
          tag_id: parseInt(tag.tagId, 10)
      };
      console.log(updateTagParams);
      const updateTagItemsForFacultyResponse = await postData(
        API_MODULES.TAGS, 
        'updateTagItemsForFaculty', 
        updateTagParams
      );

      //|| tagId=== undefined
      if (updateTagItemsForFacultyResponse.status !== 200 || isNaN(parseInt(tag.id, 10)) || parseInt(tag.id, 10) === 0) {
        // Prepare parameters for the fallback removal method
        const removeTagParams = {
            searchtext: tag.tagLabel,
            faculty_id: facultyId
        };

        // Attempt to remove the tag using the fallback endpoint
        const removeTagByIdResponse = await postData(
            API_MODULES.TAGS, 
            'removeTagByIdForFaculty', 
            removeTagParams
        );

        // If even the fallback fails, throw an error
        if (!removeTagByIdResponse.ok) {
            throw new Error("Failed to remove tag.");
        }
      }

      setTags((prevTags) => ({
        ...prevTags,
        [facultyEmail]: prevTags[facultyEmail].filter((_, index) => index !== i),
    }));

      // Notify the user of success
      toast.success("Tag removed successfully!");
    } catch (error) {
      // If anything goes wrong, show an error message and log the details
      toast.error("Failed to remove tag!");
      console.error("Error removing tag:", error);
    }
  };

  // Function to fetch tags and map them by email
  const fetchTags = async () => {
    try {
      const response = await getData(API_MODULES.TAGS, "getFacultyTagsEmail");
      if (response?.success && response.data) {
        // Create a mapping of email → list of tags
        const tagMap = response.data.reduce((acc, item) => {
          let parsedTags = [];

          // Parse JSON string if it's not null
          if (item.tags) {
            try {
              parsedTags = JSON.parse(item.tags).map((tag) => ({
                id: String(tag.tagId), 
                tagId: String(tag.tagId),
                facultyId: item.facultyId,
                tagLabel: tag.tagLabel.trim(),

              }));
            } catch (error) {
              console.error("Error parsing tags JSON:", error);
            }
          }

          acc[item.email.toLowerCase()] = parsedTags;
          return acc;
        }, {});

        setTags(tagMap);
      }
    } catch (error) {
      console.error("Error fetching faculty tags:", error);
      toast.error("Failed to fetch tags.");
    }
  };

  // useEffect(() => {
  //   console.log("Tags updated:", tags);
  // }, [tags]);

  // Fetch tags on component mount
    useEffect(() => {
      fetchTags();
    }, []);

  

  const getStaffIdForEmail = async (email) => {
    // Check if we already have this staffId
    if (emailToStaffId[email]) {
      return emailToStaffId[email];
    }
  
    try {
      const staffIdLookupParams = {
        p_email: email,
        group_name: localStorage.getItem("selectedGroup")
      };
      const staffIdResponse = await getData(API_MODULES.SEARCH, 'getStaffIdByEmail', staffIdLookupParams);
      const staffId = staffIdResponse.data[0].staff_id;
      
      // Store in state for future use
      setEmailToStaffId(prev => ({
        ...prev,
        [email]: staffId
      }));
      
      return staffId;
    } catch (error) {
      console.error("Error getting staffId for:", email);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!searchTerm) return;
    setIsLoading(true);
    setIsApiCalled(true);

    try {
      const params = {
        group_name: localStorage.getItem("selectedGroup"),
        type: activeTab === "aiir_faculty" ? "faculty" : "org",
        input_text: searchTerm
      };

      // Check cache first
      const cacheKey = `${activeTab}_${searchTerm}`;
      if (cache[cacheKey]) {
        if (activeTab === "aiir_faculty") {
          setFacultyData(cache[cacheKey] || []);
          setOrgData([]);
        } else {
          setOrgData(cache[cacheKey] || []);
          setFacultyData([]);
        }
        setIsLoading(false);
        return;
      }

      const data = await getDataFromAiirAPI(params);
      
      if (activeTab === "aiir_faculty") {
        // Get staffIds for new faculty data
        setFacultyData(data?.map(item => ({
          ...item,
          MostSimilarChunks: item.MostSimilarChunks || []
        })) || []);
        setOrgData([]);
      } else {
        setOrgData(data || []);
        setFacultyData([]);
      }

      // Cache the results
      setCache(prevCache => ({
        ...prevCache,
        [`${activeTab}_${searchTerm}`]: data
      }));

    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
      setFacultyData([]);
      setOrgData([]);
    } finally {
      setIsLoading(false);
    }

  };

  const handleTabChange = (tab) => {
    const availableTabs = getAvailableTabs();
    if (availableTabs.includes(tabFeatureMapping[tab])) {
      setActiveTab(tab);
      // if (searchTerm) {
      //   fetchSearchResults(searchTerm, tab);
      // }
    }
  };


  const facultyColumns = React.useMemo(
    () => [
      {
        // Header: "Name"
        Header: () => (
          <div className="custom-header">
            <span>Name</span>
            <span className="count-badge">{facultyData.length}</span>
          </div>
        ),
        accessor: "Name",
        Cell: ({ row }) => (
          <a href={row.original.URL} target="_blank" rel="noopener noreferrer">
            {capitalizeFirstLetter(row.original.Name)}
          </a>
        )
      },
      {
        Header: "University",
        accessor: (row) => row.Email || row.URL,
        Cell: ({ value }) => {
          const domain = extractDomain(value);
          return domain && (
            <span className={`university-text ${domain.toLowerCase()}`}>
              {domain.toUpperCase()}
            </span>
          );
        }
      },
      {
        Header: "Similarity",
        accessor: "AggregatedSimilarityScore",
        Cell: ({ value }) => (
          <span className="score-text">
            {(value * 100).toFixed(2)}%
          </span>
        )
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => (
          <ReactTags
            tags={tags[row.original.Email] || []}
            labelField="tagLabel"
            delimiters={delimiters}
            handleAddition={(tag) => handleInsertTag(tag.tagLabel, row.original.Email)}
            handleDelete={(i) => handleRemoveTag(i, row.original.Email)}
            placeholder="Select or add a new tag"
            inputFieldPosition="bottom"
            autofocus={false}
            allowDragDrop={false}
            allowDeleteFromEmptyInput={false}
            minQueryLength={1}
            maxTags={4}
          />
        )
      }
    ],
    [tags, handleInsertTag, handleRemoveTag]
  );
  
  // Helper function to extract domain from email
  // const extractDomain = (email) => {
  //   if (!email || !email.includes("@")) return null;
  //   const parts = email.split("@");
  //   if (!parts[1] || !parts[1].includes(".")) return null;
    
  //   const domainParts = parts[1].split(".");
  //   return domainParts.length > 2 ? domainParts[domainParts.length - 2] : domainParts[0];
  // };

  const extractDomain = (input) => {
    if (!input) return null;
    
    // Check if input is a URL
    if (input.startsWith('http://') || input.startsWith('https://')) {
      
      const url = new URL(input);
      const hostnameParts = url.hostname.split('.');
      return hostnameParts.length > 2 ? hostnameParts[hostnameParts.length - 2] : hostnameParts[0];
    
    }
    
    // Handle email addresses
    if (input.includes('@')) {
      const parts = input.split('@');
      if (!parts[1] || !parts[1].includes('.')) return null;
      
      const domainParts = parts[1].split('.');
      return domainParts.length > 2 ? domainParts[domainParts.length - 2] : domainParts[0];
    }
    
    return null;
  };


  const orgColumns = React.useMemo(
    () => [
      {
        Header: "Organization Name",
        accessor: "OrgName",
        Cell: ({ row }) => (
          <a
            href={
              row.original.URL.startsWith("http")
                ? row.original.URL
                : `http://${row.original.URL}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.original.OrgName}
          </a>
        ),
        sortType: "alphanumeric",
      },
    ],
    []
  );

  // Prepare CSV data based on active tab
  const csvData = activeTab === "aiir_faculty" 
    ? facultyData.map(row => ({ Name: row.Name, URL: row.URL,  Email: row.Email, Department: row.Department, Designation: row.Designation, AggregatedSimilarityScore: row.AggregatedSimilarityScore }))
    : orgData.map(row => ({ OrganizationName: row.OrgName, URL: row.URL }));

  const csvHeaders = activeTab === "aiir_faculty" 
    ? [{ label: "Faculty Name", key: "Name" }, { label: "URL", key: "URL" }, { label: "Email", key: "Email" }, { label: "Department", key: "Department" },{ label: "Designation", key: "Designation" },{ label: "AggregatedSimilarityScore", key: "AggregatedSimilarityScore" }]
    : [{ label: "Organization Name", key: "OrganizationName" }, { label: "URL", key: "URL" }];

  return (
    <div className="air-container">
      <div className="main-container">
        <div className="search-header">
          <div className="search-title">
            <div className="background-image" />
            <h1 className="section-title">
              <span className="black-on-gold">Find Your Potential Match with AIIR </span>
            </h1>
            <p>
              Leverage our cutting edge Artificial-Intelligence Information
              Retrieval (AIIR) System, driven by Semantic Analysis, to
              effortlessly identify potential faculty and organizational matches
              closely aligned with your search text. Uncover the most relevant
              connections with precision and efficiency through our advanced
              technology.
            </p>
          </div>
        </div>

        {/* Search Box Section */}
        <div className="search-box-container">
          <textarea
            type="text"
            placeholder="Enter your search text"
            className="search-box"
            value={searchTerm}
            onChange={handleSearch}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
          {searchTerm && (
            <Tooltip title="Clear Search" placement="bottom-start">
              <span className="clear-icon" onClick={clearSearch}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </Tooltip>
          )}
          <button
            type="submit"
            className="search-button"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faSearch} />
            Search
          </button>
        </div>
        
        {/* Tabs Section */}
        <div className="tabs-container">
          <div className="tabs-left">
            <div
              className={`tab ${
                activeTab === "aiir_faculty" ? "active-tab" : ""
              } ${!availableTabs.includes("aiir_faculty") ? "disabled-tab" : ""}`}
              onClick={() => handleTabChange("aiir_faculty")}
            >
              Faculty
            </div>
            <div
              className={`tab ${
                activeTab === "aiir_organization" ? "active-tab" : ""
              } ${!availableTabs.includes("aiir_organization") ? "disabled-tab" : ""}`}
              onClick={() => handleTabChange("aiir_organization")}
            >
              Organization
            </div>
          </div>
          {/* Export Button */}
          {(facultyData.length > 0 || orgData.length > 0) && (
            <div className="export-container">
              <CSVLink
                data={csvData}
                headers={csvHeaders}
                filename={`${activeTab}_data.csv`}
                className="export-link"
              >
                <Tooltip title="Export" placement="bottom-start">
                  <FontAwesomeIcon icon={faFileExport} className="export-icon" />
                </Tooltip>
              </CSVLink>
            </div>
          )}
        </div>
        
      <div className="faculty-container">
        <div className="table-container">
        {isLoading && <LoadingSpinner />}
          <div 
          className={`air-search-data ${
            facultyData.length > 0 ? "" : "full-width"
          }`}
          >
            {activeTab === "aiir_faculty" && facultyData.length > 0 ? (
                <TableWithAccordian_New 
                columns={facultyColumns} 
                data={facultyData} 
                onViewClick={handleViewFaculty} 
                selectedFacultyId={facultyData?.staffId} 
                /> 
            ) : (
              !isLoading && (
                <p className="no-data-text">
                  Search for interested topics in the search box above
                </p>
              )
            )}
            {activeTab === "aiir_organization" && orgData.length > 0 && (
              <TableWithAccordian_New columns={orgColumns} data={orgData}/>
            )}
            {isApiCalled && !isLoading &&
              searchTerm &&
              (facultyData.length === 0 && orgData.length === 0) && (
                <p className="no-data-text">No matches found</p>
              )}
          </div>
          
          {/*Faculty Detail View */}
          {facultyData.length > 0 && (
            <div className="faculty-view-data">
              {/* {console.log("Rendering faculty data:", facultyData)} */}
              { showData ? (
                  <div>
                    <div className="pdf-icon right" onClick={handleDownloadPdf}>
                      <Tooltip
                        title="Export Profile as PDF"
                        placement="bottom-start"
                      >
                        <FontAwesomeIcon icon={faShareFromSquare} />
                      </Tooltip>
                    </div>
                    <div className="faculty-view-header">
                      <div className="faculty-info-details">
                        {/* <div className="faculty-image-container"> */}
                        <div className="faculty-info-container">
                          <a
                            href={
                              facultyDetailData?.iSearchURL &&
                              facultyDetailData.iSearchURL !== "NaN"
                                ? facultyDetailData.iSearchURL
                                : "#"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="faculty-name"
                          >
                            {facultyDetailData?.name}
                          </a>
                          <p className="faculty-designation">
                            {facultyDetailData?.designation}</p>
                          <div className="faculty-details">
                            <p>
                              <strong>Email:</strong> {facultyDetailData?.email}
                            </p>
                            {/* <p>
                            <strong>Phone No.:</strong> {facultyDetailData?.phoneNumber}
                            </p> */}
                            {/* <p>
                              <strong>Address:</strong> {facultyDetailData?.address}
                            </p> */}
                            <p><strong>Department:</strong> {facultyDetailData?.department}
                            </p>
                          </div>
                          {facultyDetailData?.authorGoogleScholarId && (
                            <a
                              href={`https://scholar.google.com/citations?user=${facultyDetailData.authorGoogleScholarId}&hl=en&oi=ao`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="gs-profile-btn"
                            >
                              Google Scholar Profile
                            </a>
                          )}
                        </div>
                      </div>
                      <div
                      className="faculty-citation-details"
                      ref={facultyCitationRef}
                      >
                        <div className="citations-summary">
                          <div className="stats">
                            <span className="stat-number">
                              {facultyDetailData?.scholarCitedBy || "N/A"}
                            </span>
                            <span className="stat-description">
                              Total Citations
                            </span>
                          </div>
                          <div className="stats">
                            <span className="stat-number">
                              {facultyDetailData?.scholarCitedBy5y || "N/A"}
                            </span>
                            <span className="stat-description">5y Citations</span>
                          </div>
                          <div className="stats">
                            <span className="stat-number">
                              {facultyDetailData?.scholarPublicationCount || "N/A"}
                            </span>
                            <span className="stat-description">
                              Total Publications
                            </span>
                          </div>
                        </div>
                        <div className="citations-table">
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                <th>All</th>
                                <th>Last 5y</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Citations</td>
                                <td>{facultyDetailData?.scholarCitedBy || "N/A"}</td>
                                <td>{facultyDetailData?.scholarCitedBy5y || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>h-index</td>
                                <td>{facultyDetailData?.scholarHIndex || "N/A"}</td>
                                <td>{facultyDetailData?.scholarHIndex5y || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>i10-index</td>
                                <td>{facultyDetailData?.scholarI10Index || "N/A"}</td>
                                <td>{facultyDetailData?.scholarI10Index5y || "N/A"}</td>
                              </tr>
                              <tr>
                                <td>Last Updated</td>
                                <td></td>
                                <td>
                                  {new Date(
                                    facultyDetailData?.scrapedDate
                                  ).toLocaleDateString(undefined, {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="faculty-view-body">
                      <span className="accordion-title">Interest Topics</span>
                      <div className="interest-topics">
                        {interestTopics?.map((interestTopic, index) => (
                          <div className="interest-topic" key={index}>
                            {interestTopic}
                          </div>
                        ))}
                      </div>
                      <div className="accordian-container">
                        {/* Matching Content Accordion */}
                        {facultyDetailData?.MostSimilarChunks && facultyDetailData.MostSimilarChunks.length > 0 && (
                          <div className="accordion">
                            <div
                              className="accordion-header"
                              onClick={() => toggleAccordion("matchingContent")}
                            >
                              <span className="accordion-title">Matching Content</span>
                              <span
                                className={`accordion-icon ${accordionState.matchingContent ? 'open' : ''}`}
                              >
                                +
                              </span>
                            </div>
                            {accordionState.matchingContent && (
                              <div className="accordion-content">
                                {facultyDetailData.MostSimilarChunks.map((chunk, index) => (
                                  <li key={index}>{chunk}</li>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      
                        {/* Biography Accordion */}
                        {facultyDetailData?.bio && (
                          <div className="accordion">
                            <div
                              className="accordion-header"
                              onClick={() => toggleAccordion("biography")}
                            >
                              <span className="accordion-title">Biography</span>
                              <span
                                className={
                                  accordionState.biography
                                    ? "accordion-icon open"
                                    : "accordion-icon"
                                }
                              >
                                +
                              </span>
                            </div>
                            {accordionState.biography && (
                              <div className="accordion-content">
                                {facultyDetailData?.bio.split("\n").join("\n\n")}
                              </div>
                            )}
                          </div>
                        )}

                        {/* Research Accordion */}
                        {/* {facultyDetailData?.research && (
                          <div className="accordion">
                            <div
                              className="accordion-header"
                              onClick={() => toggleAccordion("research")}
                            >
                              <span className="accordion-title">Research</span>
                              <span
                                className={
                                  accordionState.research
                                    ? "accordion-icon open"
                                    : "accordion-icon"
                                }
                              >
                                +
                              </span>
                            </div>
                            {accordionState.research && (
                              <div className="accordion-content">
                                {facultyDetailData?.research.split(" \n").join("\n\n")}
                              </div>
                            )}
                          </div>
                        )} */}

                        {/* Public Work Accordion */}
                        {/* {facultyDetailData?.publicWork && (
                          <div className="accordion">
                            <div
                              className="accordion-header"
                              onClick={() => toggleAccordion("publicWork")}
                            >
                              <span className="accordion-title">Public Work</span>
                              <span
                                className={
                                  accordionState.publicWork
                                    ? "accordion-icon open"
                                    : "accordion-icon"
                                }
                              >
                                +
                              </span>
                            </div>
                            {accordionState.publicWork && (
                              <div className="accordion-content">
                                {facultyDetailData?.publicWork.split(" \n").join("\n\n")}
                              </div>
                            )} */}
                          {/* </div> */}
                        {/* )} */}
                      </div>
                      {/* <div className="accordion-container">
                        {/* Matching Content Section */}
                        {/* <div className="accordion">
                          <div className="accordion-header" onClick={() => toggleSection('matchingContent')}>
                            <span className="accordion-title">Matching Content</span>
                            <span className={`accordion-icon ${expandedSections.matchingContent ? 'open' : ''}`}>
                              +
                            </span>
                          </div>
                          {expandedSections.matchingContent && (
                            <div className="accordion-content">
                              {selectedFaculty.MostSimilarChunks.map((chunk, index) => (
                                <p key={index} className="mb-2">{chunk}</p>
                              ))}
                            </div>
                          )}
                        </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                ) : (
                  <p className="no-data-text">
                    Click on view button for more details
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default AIIR_New;