import React, { useState, useEffect, useRef } from "react";
import { getData, postData } from "../Services/AccessAPI";
import Table from "../Utilities/Table/Table";
import { WithContext as ReactTags } from "react-tag-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Expertise.css";
import LoadingSpinner from "../Utilities/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faShare, faShareAltSquare, faShareFromSquare, faFileExport, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import { API_MODULES } from "../Services/Settings";
import { capitalizeFirstLetter } from "../Utilities/utils";
import Avatar from '../../images/Team/Avatar.png';
import { Rsvp } from "@mui/icons-material";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Expertise = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [tags, setTags] = useState({});
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [facultyData, setFacultyData] = useState({});
  const [interestTopics, setInterestTopics] = useState([]);
  const [keywordSuggestions, setKeywordSuggestions] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [accordionState, setAccordionState] = useState({
    biography: false,
    research: false,
    publicWork: false,
  });
  const [imageError, setImageError] = useState(false);
  const [searchResultsCsv, setSearchResultsCsv] = useState([['Faculty Name', 'Designation', 'Email', 'Phone Number', 'Address', 'Department', 'Tags', 'Interest Topics', 'Google Scholar Profile', 'ASU Search URL']]);
  const facultyInfoRef = useRef();
  const facultyCitationRef = useRef();
  const csvLinkRef = useRef();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setKeywordSuggestions([]);
    setSearchResults([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (searchTerm) {
      setIsLoading(true);
      try {
        const params = {
          expertisearea: searchTerm,
          geoData: localStorage.getItem("userLocation") || ''
        };

        const data = await getData(API_MODULES.SEARCH, 'getFacultyListByExpertiseArea', params);
        setSearchResults(data.data || []);
        
        // Get keyword suggestions
        try {
        const suggestionsData = await getData(
          `get_recommendations/?query_word=${searchTerm}`
        );
        if (suggestionsData && suggestionsData.recommendations){
          setKeywordSuggestions(suggestionsData.recommendations);
        } else {
          setKeywordSuggestions([]);
        }
        
        } catch(suggestionsError) {
          console.warn("Failed to fetch recommendations:", suggestionsError);
          setKeywordSuggestions([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
        setSearchResults([]);
      } finally {
      setIsLoading(false);
    }
   }
  };

  useEffect(() => {
    setIsImageLoaded(false); 
  }, [facultyData]); 


  useEffect(() => {
    setTags((prevTags) => {
      const updatedTags = { ...prevTags };
      searchResults.forEach((faculty) => {
        const { staffId, tagData } = faculty;
        const selectedTags = tagData
          ?.filter((tag) => tag.selected)
          .map((tag, index) => ({
            id: (index + 1).toString(),
            tagLabel: tag.tagLabel,
            tagId: tag.tagId,
          }));

        updatedTags[staffId] = selectedTags;
      });

      return updatedTags;
    });
  }, [searchResults]);

  useEffect(() => {
    let isMounted = true;

    const fetchTagSuggestions = async () => {
      try {
        const data = await getData(API_MODULES.TAGS, 'getTags');
        const suggestedTags = data.data?.map((tag, index) => ({
          id: (index + 1).toString(),
          tagLabel: tag.taglabel,
        }));
        if (isMounted) {
          setSuggestedTags(suggestedTags);
        }
      } catch (error) {
        console.error("Error fetching tag suggestions:", error);
        toast.error("Error fetching tag suggestions");
      }
    };

    fetchTagSuggestions();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleViewButtonClick = async (staffId) => {
    setIsLoading(true);
    try {
      const params = { staffid: staffId };
      const data = await getData(API_MODULES.SEARCH, 'getFacultyDetailedProfile', params);
      setFacultyData(data.data[0]);
      setShowData(true);
      setAccordionState({
        biography: false,
        research: false,
        publicWork: false,
      });
      setInterestTopics(data.data[0].interestTopics !== "" ? data.data[0].interestTopics.split(", ") : []);
      setIsLoading(false);
      setImageError(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
      setIsLoading(false);
      setImageError(false);
    }
  };

  const handleExportSearchResultsClick = async () => {
    const loadId = toast.loading("Exporting as CSV");
    const params = { expertisearea: searchTerm };
  
    try {
      const response = await getData(API_MODULES.SEARCH, 'getFacultyListByExpertiseAreaForExport', params);
      console.log(response);
      if (!response.data || response.data.length === 0) {
        throw new Error("No data available to export");
      }
  
      let exportCsv = [['Faculty Name', 'Designation', 'Email', 'Phone Number', 'Address', 'Department', 'Tags', 'Interest Topics', 'Google Scholar Profile', 'ASU Search URL']];
  
      response.data.forEach((item) => {
        let tags = '';
        try {
          if (item.tags) {
            const parsedTags = JSON.parse(item.tags);
            if (Array.isArray(parsedTags)) {
              tags = parsedTags.map(tag => tag.tagLabel).join(', ');
            }
          }
        } catch (error) {
          console.error("Error parsing tags JSON:", error);
        }
  
        exportCsv.push([
          item.name || '',
          item.designation || '',
          item.email || '',
          item.phoneNumber || '',
          item.address || '',
          item.department || '',
          tags, // Use the comma-separated tags here
          item.interestTopics || '',
          item.googleScholarProfile || '',
          item.url || '' // Ensure you are using the correct property for the URL
        ]);
      });
  
      setSearchResultsCsv(exportCsv);
  
      csvLinkRef.current.link.click();
      toast.update(loadId, {
        render: "Export successful!",
        type: "success",
        isLoading: false,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.update(loadId, {
        render: "Export failed!",
        type: "error",
        isLoading: false,
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };
  

  const toggleAccordion = (accordionName) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const checkIfTagExists = (tags, tagLabel) => {
    return tags.some((tag) => tag.tagLabel.toLowerCase() === tagLabel.toLowerCase());
  }

  const handleInsertTag = async (tagLabel, facultyId) => {
    const existingTags = tags[facultyId];
    // const tagAlreadyExists = existingTags.some((tag) => tag.tagLabel === tagLabel);
    const tagAlreadyExists = checkIfTagExists(existingTags, tagLabel);

    if (tagAlreadyExists) {
      toast.warn("Tag already exists!");
      return;
    }

    if (tags[facultyId].length >= 3) {
      toast.warn(
        <div>
          Tag limit exceeded!
          <br /> Cannot add more than 3 tags
        </div>
      );
      return;
    }

    try {
      const params = { tag_label: tagLabel, faculty_id: facultyId };
      const insertTagForFacultyResponse = await postData(API_MODULES.TAGS, 'insertTagForFaculty', params);
      const tagData = await insertTagForFacultyResponse.json();
      
      if (!insertTagForFacultyResponse.ok) {
        throw new Error("Failed to insert tag.");
      }

      setTags((prevTags) => {
        const updatedTags = { ...prevTags };
        if (updatedTags.hasOwnProperty(facultyId)) {
          const existingTags = updatedTags[facultyId];
          // const tagAlreadyExists = existingTags.some((tag) => tag.tagLabel === tagLabel);
          const tagAlreadyExists = checkIfTagExists(existingTags, tagLabel);
          if (!tagAlreadyExists) {
            const updatedFacultyTags = [
              ...existingTags,
              {
                id: (existingTags.length + 1).toString(),
                tagLabel: tagLabel,
                tagId: tagData?.data,
              },
            ];
            updatedTags[facultyId] = updatedFacultyTags;
          }
        } else {
          updatedTags[facultyId] = [{ id: "1", tagLabel: tagLabel }];
        }
        return updatedTags;
      });

      setSuggestedTags((prevTags) => {
        // const tagAlreadyExists = prevTags.some((tag) => tag.tagLabel === tagLabel);
        const tagAlreadyExists = checkIfTagExists(prevTags, tagLabel);
        if (tagAlreadyExists) {
          return prevTags;
        } else {
          return [
            ...prevTags,
            { id: (prevTags.length + 1).toString(), tagLabel: tagLabel },
          ];
        }
      });

      toast.success("Tag added successfully!");
    } catch (error) {
      toast.error("Failed to add tag!");
      console.error("Error inserting tag:", error);
    }
  };

  const handleRemoveTag = async (i, facultyId) => {
    console.log("in handleRemoveTag: ", tags[facultyId], i);
    let tag = tags[facultyId][i];
    // const tagIdShifted = tag.tagId << 4;

    try {
      const updateTagParams = {
        faculty_id: facultyId,
        tag_id: tag.tagId
      };

      const updateTagItemsForFacultyResponse = await postData(API_MODULES.TAGS, 'updateTagItemsForFaculty', updateTagParams);

      if (!updateTagItemsForFacultyResponse.ok || typeof tag.tagId !== "number" || tag.tagId === 0) {
        const removeTagParams = {
          searchtext: tag.tagLabel,
          faculty_id: facultyId
        };

        const removeTagByIdResponse = await postData(API_MODULES.TAGS, 'removeTagByIdForFaculty', removeTagParams);

        if (!removeTagByIdResponse.ok) {
          throw new Error("Failed to remove tag.");
        }
      }

      // Update Tags
      setTags((prevTags) => ({
        ...prevTags,
        [facultyId]: prevTags[facultyId].filter((_, index) => index !== i),
      }));

      // Toast message
      toast.success("Tag removed successfully!");
    } catch (error) {
      toast.error("Failed to remove tag!");
      console.error("Error removing tag:", error);
    }
  };

  // const handleRemoveTag = async (i, facultyId) => {
  //   let tag = tags[facultyId][i];
  //   // let shiftedFacultyId = facultyId >> 4;
  //   const formData1 = new FormData();
  //   formData1.append("facultyId", facultyId);
  //   formData1.append("tagId", tag.tagId << 4); // Left shifting tag.id as we right shift it again in the backend

  //   const formData2 = new FormData();
  //   formData2.append("TagLabel", tag.tagLabel);
  //   formData2.append("facultyId", facultyId);

  //   try {
  //     const updateTagItemsForFacultyResponse = await postData(
  //       `api/Tag/updateTagItemsForFaculty`,
  //       {
  //         faculty_id: facultyId,
  //         tag_id: tag.tagId << 4,
  //       }
  //     );

  //     if (!updateTagItemsForFacultyResponse.ok || typeof tag.tagId !== "number" || tag.tagId === 0) {


  //       const removeTagByIdResponse = await postData(
  //         `api/Tag/removeTagByIdForFaculty`,
  //         {
  //           searchtext: tag.tagLabel,
  //           facultyId: facultyId,
  //         }
  //       );

  //       if (!removeTagByIdResponse.ok) {
  //         throw new Error("Failed to remove tag.");
  //       }
  //     }

  //     // Update Tags
  //     setTags((prevTags) => ({
  //       ...prevTags,
  //       [facultyId]: prevTags[facultyId].filter((_, index) => index !== i),
  //     }));

  //     // Toast message
  //     toast.success("Tag removed successfully!");
  //   } catch (error) {
  //     toast.error("Failed to remove tag!");
  //     console.error("Error removing tag:", error);
  //   }
  // };

  const facultyColumns = React.useMemo(
    () => [
      {
        // Header: "Name",
        Header: () => (
          <div className="custom-header">
            <span>Name</span>
            <span className="count-badge">{searchResults.length}</span>
          </div>
        ),
        accessor: "name",
        // Enable sorting for this column
        sortType: "alphanumeric",
        Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: "University",
        accessor: "university",
        Cell: ({ value }) => {
          if (!value) return null;
          return <span className={`university-text ${value.toLowerCase()}`}>{value}</span>;
        },
        // Cell: ({ value }) => capitalizeFirstLetter(value),
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => (
          <ReactTags
            tags={tags[row.original.staffId] || []}
            labelField="tagLabel"
            suggestions={suggestedTags}
            delimiters={delimiters}
            handleAddition={(tag) => {
              handleInsertTag(tag.tagLabel, row.original.staffId);
            }}
            handleDelete={(i) => {
              handleRemoveTag(i, row.original.staffId);
            }}
            placeholder="Select or add a new tag"
            inputFieldPosition="bottom"
            autofocus={false}
            allowDragDrop={false}
            allowDeleteFromEmptyInput={false}
            minQueryLength={1}
            maxTags={4}
            // autocomplete
          />
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <a
            className="view-button"
            tabIndex="0"
            onClick={() => handleViewButtonClick(row.original.staffId)}
          >
            View
          </a>
        ),
      },
    ],
    [searchResults.length, tags, suggestedTags]
  );

  const handleDownloadPdf = async () => {
    // Ensure both refs are present
    if (!facultyInfoRef.current || !facultyCitationRef.current) {
      console.error("Missing references.");
      return;
    }

    // Capture both sections as canvas images
    const canvasInfo = await html2canvas(facultyInfoRef.current, {
      useCors: true,
      scale: 1,
    }); // Adjust scale as needed
    const canvasCitation = await html2canvas(facultyCitationRef.current, {
      useCors: true,
      scale: 1,
    }); // Adjust scale as needed

    // Convert canvases to data URLs
    const dataInfo = canvasInfo.toDataURL("image/png");
    const dataCitation = canvasCitation.toDataURL("image/png");

    // Initialize jsPDF
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const padding = 10; // Define padding around images

    // Define a generic function to add images to PDF with padding
    const addImageToPdf = (dataUrl, xPos, yPos) => {
      const imgProps = pdf.getImageProperties(dataUrl);
      const availableWidth = pdfWidth / 2 - 2 * padding; // Half width minus padding for each side
      const scaledHeight = (imgProps.height * availableWidth) / imgProps.width; // Scale height to maintain aspect ratio

      pdf.addImage(
        dataUrl,
        "PNG",
        xPos + padding,
        yPos + padding,
        availableWidth,
        scaledHeight
      );
      return scaledHeight + 2 * padding; // Height of the image including padding
    };

    // Add both images to the PDF, side by side, with padding
    const infoImageHeight = addImageToPdf(dataInfo, 0, 0); // Left half, starting yPos = 10
    const citationImageHeight = addImageToPdf(dataCitation, pdfWidth / 2, 0); // Right half, starting yPos = 10

    // Determine the starting position for the next section of the PDF content
    let yOffset = Math.max(infoImageHeight, citationImageHeight) - 10; // '-10' to reduce the gap btwn images and next heading

    const textData = [
      { heading: "Interest Topics", content: facultyData?.interestTopics },
      { heading: "Biography", content: facultyData?.bio },
      { heading: "Research", content: facultyData?.research },
      { heading: "Public Work", content: facultyData?.publicWork },
    ];

    // let yOffset = pdfHeight + 10; // Start Y position for text

    textData.forEach(({ heading, content }) => {
      if (content) {
        const paragraphs =
          heading === "Interest Topics"
            ? [content]
            : content.split("\n").join("\n\n").split("\n");
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text(10, yOffset, heading);

        yOffset += 8; // Add some padding below the heading

        paragraphs.forEach((paragraph) => {
          const lines = pdf.splitTextToSize(paragraph, pdfWidth - 20); // Adjust for margins
          const lineHeight = pdf.getLineHeight() / pdf.internal.scaleFactor;
          if (yOffset + lineHeight > pdf.internal.pageSize.getHeight() - 20) {
            pdf.addPage(); // Add a new page if the content exceeds the current page
            yOffset = 10; // Reset Y offset for the new page
          }
          lines.forEach((line) => {
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(10);
            pdf.text(10, yOffset, line);
            yOffset += lineHeight;
          });
        });

        yOffset += 6; // Add some padding between sections
      }
    });

    pdf.save(`Faculty Profile - ${facultyData?.name}.pdf`);
  };

  return (
    <div className="search-container">
      <div className="main-container">
        {/* Search Header Section */}
        <div className="search-header">
          <div className="search-title">
            <div className="background-image" />
            <h1 className="section-title">
              <span className="black-on-gold">Knowledge Alliance Search</span>
            </h1>
            <p>
            Discover faculty and researchers based on your research interests with our Expertise Search Engine, designed to match your search terms using AND or OR for multiple keyword combinations.</p>
          </div>
        </div>

        {/* Search Box Section */}
        <div className="search-box-container">
          <input
            type="text"
            placeholder="Search faculty by expertise area. Use AND or OR for multiple keyword searches."
            className="search-box"
            value={searchTerm}
            onChange={handleSearch}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
          {searchTerm && (
          <Tooltip title="Clear Search" placement="bottom-start">
            <span className="clear-icon" onClick={clearSearch}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </Tooltip>
        )}
        <button
          type="submit"
          className="search-button"
          onClick={handleSubmit}
        >
          <FontAwesomeIcon icon={faSearch} />
          Search
        </button>
      </div>

      {/* Keyword Suggestions Section */}
      {searchTerm && keywordSuggestions.length > 0 && (
        <div className="keyword-suggestions">
          <span className="suggestions-title">
            Related Expertise Areas:
          </span>
          <div className="keyword-suggestions-container">
            {keywordSuggestions?.map((keyword, index) => (
              <div
                className="keyword"
                key={index}
                onClick={() => {
                  setSearchTerm(keyword);
                  handleSubmit({ preventDefault: () => {} });
                }}
              >
                {keyword}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="faculty-container">
        <div className="title-container">
          <h2 className="container-title">Search Faculty by Expertise Area</h2>
          {searchResults.length > 0 && (
            <>
              <span
                className="export-icon"
                onClick={handleExportSearchResultsClick}
              >
                <Tooltip
                  title="Export Search Results as CSV"
                  placement="bottom-start"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                </Tooltip>
              </span>
              <CSVLink
                data={searchResultsCsv}
                filename={`${searchTerm}_faculty_search_results.csv`}
                className="hidden"
                ref={csvLinkRef}
                target="_blank"
              />
            </>
          )}
        </div>
        <div className="table-container">
          {isLoading && <LoadingSpinner />}
          <div
            className={`faculty-search-data ${
              searchResults.length > 0 ? "" : "full-width"
            }`}
          >
            {searchResults.length > 0 ? (
              <>
                <Table
                  columns={facultyColumns}
                  data={searchResults}
                  disableFilterOptionsForIds={["tags", "action"]}
                />
              </>
            ) : (
              !isLoading && (
                <p className="no-data-text">
                  Search for interested topics in the search box above
                </p>
              )
            )}
          </div>
          {searchResults.length > 0 && (
            <div className="faculty-view-data">
              {showData ? (
                <div>
                  <div className="pdf-icon right" onClick={handleDownloadPdf}>
                    <Tooltip
                      title="Export Profile as PDF"
                      placement="bottom-start"
                    >
                      <FontAwesomeIcon icon={faShareFromSquare} />
                    </Tooltip>
                  </div>
                  <div className="faculty-view-header">
                    <div className="faculty-info-details">
                      <div className="faculty-image-container">
                        <a
                          href={
                            facultyData?.iSearchURL &&
                            facultyData.iSearchURL !== "NaN"
                              ? facultyData.iSearchURL
                              : "#"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="image-link-container"
                        >
                          <img
                            src={
                              isImageLoaded &&
                              !imageError &&
                              facultyData?.iSearchImageURL &&
                              facultyData.iSearchImageURL !== "NaN"
                                ? facultyData.iSearchImageURL
                                : Avatar
                            }
                            alt="Faculty Image"
                            onLoad={() => setIsImageLoaded(true)} 
                            onError={handleImageError} 
                            loading="lazy" 
                          />
                          <div className="image-overlay">
                            <span className="image-overlay-text">
                              Link to Profile
                            </span>
                          </div>
                        </a>
                        {facultyData?.authorGoogleScholarId && (
                          <a
                            href={`https://scholar.google.com/citations?user=${facultyData.authorGoogleScholarId}&hl=en&oi=ao`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="gs-profile-btn"
                          >
                            Google Scholar Profile
                          </a>
                        )}
                      </div>

                      <div
                        className="faculty-info-container"
                        ref={facultyInfoRef}
                      >
                        <a
                          href={facultyData?.iSearchURL}
                          className="faculty-name"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {facultyData?.name}
                        </a>
                        <p className="faculty-designation">
                          {facultyData?.designation}
                        </p>
                        <div className="faculty-details">
                          <p>
                            <strong>Email:</strong> {facultyData?.email}
                          </p>
                          <p>
                            <strong>Phone No.:</strong> {facultyData?.phoneNumber}
                          </p>
                          <p>
                            <strong>Address:</strong> {facultyData?.address}
                          </p>
                          <p>
                            <strong>Department:</strong> {facultyData?.department}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="faculty-citation-details"
                      ref={facultyCitationRef}
                    >
                      <div className="citations-summary">
                        <div className="stats">
                          <span className="stat-number">
                            {facultyData?.scholarCitedBy || "N/A"}
                          </span>
                          <span className="stat-description">
                            Total Citations
                          </span>
                        </div>
                        <div className="stats">
                          <span className="stat-number">
                            {facultyData?.scholarCitedBy5y || "N/A"}
                          </span>
                          <span className="stat-description">5y Citations</span>
                        </div>
                        <div className="stats">
                          <span className="stat-number">
                            {facultyData?.scholarPublicationCount || "N/A"}
                          </span>
                          <span className="stat-description">
                            Total Publications
                          </span>
                        </div>
                      </div>
                      <div className="citations-table">
                        <table>
                          <thead>
                            <tr>
                              <th></th>
                              <th>All</th>
                              <th>Last 5y</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Citations</td>
                              <td>{facultyData?.scholarCitedBy || "N/A"}</td>
                              <td>{facultyData?.scholarCitedBy5y || "N/A"}</td>
                            </tr>
                            <tr>
                              <td>h-index</td>
                              <td>{facultyData?.scholarHIndex || "N/A"}</td>
                              <td>{facultyData?.scholarHIndex5y || "N/A"}</td>
                            </tr>
                            <tr>
                              <td>i10-index</td>
                              <td>{facultyData?.scholarI10Index || "N/A"}</td>
                              <td>{facultyData?.scholarI10Index5y || "N/A"}</td>
                            </tr>
                            <tr>
                              <td>Last Updated</td>
                              <td></td>
                              <td>
                                {new Date(
                                  facultyData?.scrapedDate
                                ).toLocaleDateString(undefined, {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="faculty-view-body">
                    <span className="accordion-title">Interest Topics</span>
                    <div className="interest-topics">
                      {interestTopics?.map((interestTopic, index) => (
                        <div className="interest-topic" key={index}>
                          {interestTopic}
                        </div>
                      ))}
                    </div>
                    <div className="accordian-container">
                      {/* Biography Accordion */}
                      {facultyData?.bio && (
                        <div className="accordion">
                          <div
                            className="accordion-header"
                            onClick={() => toggleAccordion("biography")}
                          >
                            <span className="accordion-title">Biography</span>
                            <span
                              className={
                                accordionState.biography
                                  ? "accordion-icon open"
                                  : "accordion-icon"
                              }
                            >
                              +
                            </span>
                          </div>
                          {accordionState.biography && (
                            <div className="accordion-content">
                              {facultyData?.bio.split("\n").join("\n\n")}
                            </div>
                          )}
                        </div>
                      )}

                      {/* Research Accordion */}
                      {facultyData?.research && (
                        <div className="accordion">
                          <div
                            className="accordion-header"
                            onClick={() => toggleAccordion("research")}
                          >
                            <span className="accordion-title">Research</span>
                            <span
                              className={
                                accordionState.research
                                  ? "accordion-icon open"
                                  : "accordion-icon"
                              }
                            >
                              +
                            </span>
                          </div>
                          {accordionState.research && (
                            <div className="accordion-content">
                              {facultyData?.research.split(" \n").join("\n\n")}
                            </div>
                          )}
                        </div>
                      )}

                      {/* Public Work Accordion */}
                      {facultyData?.publicWork && (
                        <div className="accordion">
                          <div
                            className="accordion-header"
                            onClick={() => toggleAccordion("publicWork")}
                          >
                            <span className="accordion-title">Public Work</span>
                            <span
                              className={
                                accordionState.publicWork
                                  ? "accordion-icon open"
                                  : "accordion-icon"
                              }
                            >
                              +
                            </span>
                          </div>
                          {accordionState.publicWork && (
                            <div className="accordion-content">
                              {facultyData?.publicWork.split(" \n").join("\n\n")}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <p className="no-data-text">
                  Click on view button for more details
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
  );
};

export default Expertise;